<template>
    <v-dialog :persistent="revoking" v-model="dialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn small v-if="actionType === actionTypes.REVOKE_INVITATION" v-on="{ ...tooltip, ...dialog }" icon
                        ><v-icon small color="primary">clear</v-icon></v-btn
                    >
                    <v-btn small v-else-if="actionType === actionTypes.RESEND_INVITATION" v-on="{ ...tooltip, ...dialog }" icon
                        ><v-icon small color="primary">reply</v-icon></v-btn
                    >
                </template>
                <span v-if="actionType === actionTypes.REVOKE_INVITATION">
                    Revoke invitation
                </span>
                <span v-else-if="actionType === actionTypes.RESEND_INVITATION">Resend invitation</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center primary--text">
                    <v-icon v-if="actionType === actionTypes.REVOKE_INVITATION" class="mr-1">clear</v-icon>
                    <v-icon v-else-if="actionType === actionTypes.RESEND_INVITATION" class="mr-1">reply</v-icon>
                    <span v-if="actionType === actionTypes.REVOKE_INVITATION" class="text-uppercase">invitation revoke</span>
                    <span v-else-if="actionType === actionTypes.RESEND_INVITATION" class="text-uppercase">invitation re-send</span>
                </div></v-card-title
            >
            <v-spacer></v-spacer>
            <v-divider></v-divider>

            <v-card-text class="mt-1">
                <div class="d-flex flex-column">
                    <span v-if="actionType === actionTypes.REVOKE_INVITATION" class="subtitle-1">Are you sure you want to revoke this invitation:</span>
                    <span v-else-if="actionType === actionTypes.RESEND_INVITATION" class="subtitle-1">Are you sure you want to re-send this invitation:</span>
                    <v-card class="mt-3" flat outlined>
                        <v-card-text>
                            <div class="d-flex flex-column">
                                <span class="caption font-weight-bold text-uppercase">invitee</span>
                                {{ invitationInfo.requestee_email }}
                                <span class="caption font-weight-bold text-uppercase mb-2 mt-5">roles</span>
                                <div class="d-flex flex-column mb-1" v-for="(role, index) in invitationInfo.roles" :key="index">
                                    <span v-if="role.role_type === invitationRoleTypes.INSTANCE_ROLE" class="d-flex align-center">
                                        <v-icon v-if="role.Name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">visibility</v-icon>
                                        <v-icon v-else-if="role.Name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                        {{ role.Instance }}
                                    </span>
                                    <span v-else-if="role.role_type === invitationRoleTypes.ORG_ROLE" class="d-flex align-center">
                                        <v-icon class="mr-1 ml-1">apartment</v-icon>
                                        {{ role.Organization }}
                                    </span>
                                    <span v-else-if="role.role_type === invitationRoleTypes.SPACE_ROLE" class="d-flex align-center">
                                        <v-icon class="mr-1 ml-1">security</v-icon>
                                        {{ role.Space }}
                                    </span>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="actionType === actionTypes.REVOKE_INVITATION" :loading="revoking" @click="revokeInvitation" color="error" text>revoke</v-btn>
                <v-btn v-if="actionType === actionTypes.RESEND_INVITATION" :loading="revoking" @click="resendInvitation" color="error" text>send</v-btn>
                <v-btn @click="dialog = false" color="primary" text :disabled="revoking">cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

import { mapState } from 'vuex'

export default {
    name: 'InvitationRevokeDialog',
    mixins: [enumsData],

    props: {
        successDispatchString: String,
        successDispatchId: String,
        invitationInfo: Object,
        actionType: String
    },
    data() {
        return {
            dialog: false,
            revoking: false,
            actionTypes: {
                REVOKE_INVITATION: 'revoke',
                RESEND_INVITATION: 'resend'
            }
        }
    },
    computed: {
        ...mapState(['globalDialog', 'globalDialogTitle', 'globalDialogText', 'globalDialogAction', 'userOrgs'])
    },
    methods: {
        revokeInvitation: function() {
            this.$data.revoking = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Revoking invitation, please wait....',
                snackBarTimeout: 5000,
                snackBarIcon: 'info'
            })
            const apiURL = `/invitations/revoke/${this.$props.invitationInfo.token}`
            this.$axios
                .get(apiURL, { timeout: 300000 })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Invitation successfully revoked!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch(this.$props.successDispatchString, this.$props.successDispatchId)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to revoke invitation, please try again later.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.dialog = false
                    this.$data.revoking = false
                })
        },
        resendInvitation: function() {
            this.$data.revoking = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Resending invitation, please wait....',
                snackBarTimeout: 5000,
                snackBarIcon: 'info'
            })
            const apiURL = `/invitations/resend/${this.$props.invitationInfo.token}`
            this.$axios
                .get(apiURL, { timeout: 300000 })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Invitation successfully resent!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch(this.$props.successDispatchString, this.$props.successDispatchId)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to resend invitation, please try again later.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.$data.dialog = false
                    this.$data.revoking = false
                })
        }
    },
    watch: {
        globalDialog: function(nextVal, preVal) {
            this.$data.dialog = nextVal
        }
    },
    beforeDestroy() {
        this.$store.dispatch('hideGlobalDialog')
    }
}
</script>
