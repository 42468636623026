var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.revoking, "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var dialog = ref.on
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var tooltip = ref.on
                          return [
                            _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    Object.assign({}, tooltip, dialog)
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "", color: "primary" }
                                      },
                                      [_vm._v("clear")]
                                    )
                                  ],
                                  1
                                )
                              : _vm.actionType ===
                                _vm.actionTypes.RESEND_INVITATION
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    { attrs: { small: "", icon: "" } },
                                    Object.assign({}, tooltip, dialog)
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { small: "", color: "primary" }
                                      },
                                      [_vm._v("reply")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                    ? _c("span", [_vm._v(" Revoke invitation ")])
                    : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                    ? _c("span", [_vm._v("Resend invitation")])
                    : _vm._e()
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center primary--text" },
              [
                _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                  ? _c("v-icon", { staticClass: "mr-1" }, [_vm._v("clear")])
                  : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                  ? _c("v-icon", { staticClass: "mr-1" }, [_vm._v("reply")])
                  : _vm._e(),
                _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                  ? _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v("invitation revoke")
                    ])
                  : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                  ? _c("span", { staticClass: "text-uppercase" }, [
                      _vm._v("invitation re-send")
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                  ? _c("span", { staticClass: "subtitle-1" }, [
                      _vm._v("Are you sure you want to revoke this invitation:")
                    ])
                  : _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                  ? _c("span", { staticClass: "subtitle-1" }, [
                      _vm._v(
                        "Are you sure you want to re-send this invitation:"
                      )
                    ])
                  : _vm._e(),
                _c(
                  "v-card",
                  { staticClass: "mt-3", attrs: { flat: "", outlined: "" } },
                  [
                    _c("v-card-text", [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase"
                            },
                            [_vm._v("invitee")]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.invitationInfo.requestee_email) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "caption font-weight-bold text-uppercase mb-2 mt-5"
                            },
                            [_vm._v("roles")]
                          ),
                          _vm._l(_vm.invitationInfo.roles, function(
                            role,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "d-flex flex-column mb-1"
                              },
                              [
                                role.role_type ===
                                _vm.invitationRoleTypes.INSTANCE_ROLE
                                  ? _c(
                                      "span",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        role.Name ===
                                        _vm.roleTypes.INSTANCE_VIEWER
                                          ? _c(
                                              "v-icon",
                                              { staticClass: "mr-1 ml-1" },
                                              [_vm._v("visibility")]
                                            )
                                          : role.Name ===
                                            _vm.roleTypes.INSTANCE_EDITOR
                                          ? _c(
                                              "v-icon",
                                              { staticClass: "mr-1 ml-1" },
                                              [_vm._v("edit")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " " + _vm._s(role.Instance) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : role.role_type ===
                                    _vm.invitationRoleTypes.ORG_ROLE
                                  ? _c(
                                      "span",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mr-1 ml-1" },
                                          [_vm._v("apartment")]
                                        ),
                                        _vm._v(
                                          " " + _vm._s(role.Organization) + " "
                                        )
                                      ],
                                      1
                                    )
                                  : role.role_type ===
                                    _vm.invitationRoleTypes.SPACE_ROLE
                                  ? _c(
                                      "span",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "mr-1 ml-1" },
                                          [_vm._v("security")]
                                        ),
                                        _vm._v(" " + _vm._s(role.Space) + " ")
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.actionType === _vm.actionTypes.REVOKE_INVITATION
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.revoking,
                        color: "error",
                        text: ""
                      },
                      on: { click: _vm.revokeInvitation }
                    },
                    [_vm._v("revoke")]
                  )
                : _vm._e(),
              _vm.actionType === _vm.actionTypes.RESEND_INVITATION
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.revoking,
                        color: "error",
                        text: ""
                      },
                      on: { click: _vm.resendInvitation }
                    },
                    [_vm._v("send")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", disabled: _vm.revoking },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("cancel")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }